import { Reducer } from 'redux'
import { ComponentLoadingActionTypes } from './actions'

export type ComponentLoadingState = Record<string, number>

export enum LoadingTypes {
  GENERAL = 'GENERAL',
  CRM_AUTH = 'CRM_AUTH',
  TABLE_MAPPING = 'TABLE_MAPPING',
  CRM_FIELDS = 'CRM_FIELDS',
  CMCM_FETCHING_ACCOUNTS = 'CMCM_FETCHING_ACCOUNTS',
  AZURE_FETCHING_ACCOUNT_DETAILS = 'AZURE_FETCHING_ACCOUNT_DETAILS',
  AZURE_DISCONNECTING_ACCOUNT = 'AZURE_DISCONNECTING_ACCOUNT',
  SLACK_CHANNELS_LOADING = 'SLACK_CHANNELS_LOADING',
  CRM_CONFIGURATION = 'CRM_CONFIGURATION',
  CRM_CONFIGURATION_AUTOFILL = 'CRM_CONFIGURATION_AUTOFILL',
  CRM_TABLES_FIELDS_DETAILS = 'CRM_TABLES_FIELDS_DETAILS',
  RESELLERS_DATA = 'RESELLERS_DATA',
  TEMPLATES = 'TEMPLATES',
  PRODUCT_INFORMATION = 'PRODUCT_INFORMATION',
  COMPANY_INFORMATION = 'COMPANY_INFORMATION',
  SUBMIT_IAM_POLICY = 'SUBMIT_IAM_POLICY',
  SUBMIT_FLYOUT_ONBOARDING = 'SUBMIT_FLYOUT_ONBOARDING',
  S3_CONNECTION = 'S3_CONNECTION',
  CRM_CONNECTION = 'CRM_CONNECTION',
  CLOUD_ACCOUNTS = 'CLOUD_ACCOUNTS',
  PRODUCTS_AND_SERVICES_CHECK = 'PRODUCTS_AND_SERVICES_CHECK',
  MARKETPLACE_INFO = 'MARKETPLACE_INFO',
  FLYOUT_ONBOARDING_DATA = 'FLYOUT_ONBOARDING_DATA',
  FLYOUT_OVERVIEW = 'FLYOUT_OVERVIEW',
  FLYOUT_ONBOARDING_MIGRATION_PRODUCTS = 'FLYOUT_ONBOARDING_MIGRATION_PRODUCTS',
  COSELL = 'COSELL',
  COSELL_FETCH_REFERRAL = 'COSELL_FETCH_REFERRAL',
  COSELL_FETCH_REFERRALS = 'COSELL_FETCH_REFERRALS',
  RENEW_AGREEMENT_BASED_OFFER = 'RENEW_AGREEMENT_BASED_OFFER',
  FILE_PROCESSING_LOADER = 'FILE_PROCESSING_LOADER',
  COSELL_PARTNER_CENTRAL_USERS_DATA = 'COSELL_PARTNER_CENTRAL_USERS_DATA',
  ADD_NEW_USER = 'ADD_NEW_USER',
  COSELL_DELETE_REFERRAL = 'COSELL_DELETE_REFERRAL',
  SUBMIT_FLYOUT_Offer = 'SUBMIT_FLYOUT_Offer',
  FLYOUT_PRODUCT_DETAIL = 'FLYOUT_PRODUCT_DETAIL',
  SUBMIT_FLYOUT_PRODUCT_DETAIL = 'SUBMIT_FLYOUT_PRODUCT_DETAIL',
  FLYOUT_PRODUCT_MIGRATION_PRODUCTS = 'FLYOUT_PRODUCT_MIGRATION_PRODUCTS',
  PARTNER_DATA = 'PARTNER_DATA',
  SET_SEGMENT_AND_GREEN_FIELD = 'SET_SEGMENT_AND_GREEN_FIELD',
  G2_REVIEW = 'G2_REVIEW',
  SAVE_COMPETITOR = 'SAVE_COMPETITOR',
  FETCH_COMPETITOR = 'FETCH_COMPETITOR',
  SEARCH_SEO_RANKING = 'SEARCH_SEO_RANKING',
  FETCH_KEYWORD_COMPETITOR_MAPPING = 'FETCH_KEYWORD_COMPETITOR_MAPPING',
  COSELL_FETCH_LABRA_REFERRAL_STATUS_HISTORY = 'COSELL_FETCH_LABRA_REFERRAL_STATUS_HISTORY',
  UPDATE_FLYOUT_OFFER = 'UPDATE_FLYOUT_OFFER',
  CREATE_TEMPLATE = 'CREATE_TEMPLATE',
  CREATE_LISTING_CARD = 'CREATE_LISTING_CARD',
}
export const initialState: ComponentLoadingState = {
  GENERAL: 0,
  CRM_AUTH: 0,
  TABLE_MAPPING: 0,
  CRM_FIELDS: 0,
  CMCM_FETCHING_ACCOUNTS: 0,
  AZURE_FETCHING_ACCOUNT_DETAILS: 0,
  AZURE_DISCONNECTING_ACCOUNT: 0,
}

export type ComponentLoadingAction = {
  type: ComponentLoadingActionTypes
  payload: string
}

export const reducer: Reducer<ComponentLoadingState, ComponentLoadingAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ComponentLoadingActionTypes.START_LOADING: {
      const count = (state[action.payload] || 0) + 1
      return { ...state, [action.payload]: count }
    }
    case ComponentLoadingActionTypes.STOP_LOADING: {
      const count = (state[action.payload] || 0) - 1
      return { ...state, [action.payload]: count }
    }
    default: {
      return state
    }
  }
}
