import { CloudMarketplace } from '../../../flyout/modules/productsListing/reducer'
import {
  awsSellerFetchLambdaUrl,
  marketplaceSeoServiceUrl,
  marketplaceServiceUrl,
} from '../../utils/constants'
import { API } from '../index'
import axios from 'axios'

export const fetchCompetitorName = (productId: string) =>
  axios.get(`${awsSellerFetchLambdaUrl}/vendors?product_id=${productId}`)

export const saveCompetitorName = (partnerId: string, data: any) =>
  API.post(
    `${marketplaceSeoServiceUrl}/partners/${partnerId}/competitors/_bulk`,
    data
  )
export const fetchCompetitorsList = (
  partnerId: string,
  cloud: CloudMarketplace
) =>
  API.get(`${marketplaceSeoServiceUrl}/partners/${partnerId}/competitors`, {
    params: {
      cloud: cloud,
    },
  })

export const fetchKeywordsList = (partnerId: string, cloud: CloudMarketplace) =>
  API.get(
    `${marketplaceSeoServiceUrl}/partners/${partnerId}/keywords/keywords_list`,
    {
      params: {
        cloud: cloud,
      },
    }
  )

export const fetchSelfListingUrl = (partnerId: string) =>
  API.get(`${marketplaceServiceUrl}/partners/${partnerId}`, {
    params: {
      get_seo_search_keywords: true,
    },
  })

export const fetchKeywordCompetitorMapping = (
  partnerId: string,
  cloud: CloudMarketplace
) =>
  API.get(
    `${marketplaceSeoServiceUrl}/partners/${partnerId}/keywords_competitors_map`,
    {
      params: {
        cloud: cloud,
      },
    }
  )

export const saveKeywordRecord = (partnerId: string, data: any) =>
  API.post(
    `${marketplaceSeoServiceUrl}/partners/${partnerId}/keywords/_bulk`,
    data
  )

export const updateKeywordRecord = (partnerId: string, data: any) =>
  API.patch(
    `${marketplaceSeoServiceUrl}/partners/${partnerId}/keywords/_bulk`,
    data
  )

export const searchKeywordsRankingList = (
  partnerId: string,
  cloud: CloudMarketplace
) => API.get(`${marketplaceSeoServiceUrl}/partners/${partnerId}/seo_records`)

export const startRankingProcess = (
  partnerId: string,
  cloud: CloudMarketplace
) =>
  API.post(
    `${marketplaceSeoServiceUrl}/partners/${partnerId}/seo_records/start_ranking_process?cloud=${cloud}`
  )

export const deleteCompetitor = (partnerId: string, competitorId: string) =>
  API.delete(
    `${marketplaceSeoServiceUrl}/partners/${partnerId}/competitors/${competitorId}`
  )
