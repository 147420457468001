import React, { useMemo } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'
import { NavigationRoutesType } from '@labrav/react-components/lib/@types/v2/components/SidePanel/SidePanel'

import { ProductType } from '../../modules/partner/reducer'
import {
  ActiveProducts,
  PartnerData,
  Product,
} from '../../modules/partner/action'

import { useSelector } from '../../../store'
import { UserProfile } from '../../modules/userProfile/action'
import { Role } from '../../utils/roles'
import {
  FlyOutNavigationRoutes,
  OppSyncNavigationRoutes,
  OppSyncOnboardingRoutes,
  productSelectorDataV2,
  FlyoutOnboardingRoutes,
  OppSyncNavigationRoutesCosell,
  FlyoutOverviewRoute,
  marketplaceSeo,
  FlyoutStorefrontRoute,
  OppsyncPropensityRoute,
} from './SidePanelWrapperRoutesV2'
import { UserProfileState } from '../../modules/userProfile/reducer'
import { errorLogger } from '../../utils/errorLogger'
import { SidePanelV2 } from '@labrav/react-components'
import { snakeToSentenceCase } from '../../utils/strings'
import { useOppSyncOnboardingStatus } from '../../../oppsync/utils/hooks/useOppSyncOnboardingStatus'
import { useAuth0 } from '@auth0/auth0-react'
import { useFlag, useFlagValue } from '@labrav/flags'
import {
  FlyoutOnboardingSectionName,
  Sections,
} from '../../../flyout/modules/flyOutOnboarding/reducer'
import {
  FlyOutListingData,
  FlyOutListingUserType,
} from '../../../admin/modules/flyOutSyncs/reducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faBadgeCheck,
  faLock,
  faFileContract,
} from '@fortawesome/pro-regular-svg-icons'
import { TeamMembersWrapper } from './TeamMembersWrapper'
import { useSellerInfo } from '../../utils/Hooks/useSellerInfo'
import { useCrmAuthInfo } from '../../utils/Hooks/useCrmAuthInfo'
import { LoadingTypes } from '../../modules/loading/reducer'
import { isLoading } from '../../utils/loadingState'
import { customerFormData } from '../../utils/Hooks/usePartnerData'

interface SidePanelWrapperProps {
  isNotInProductSelection: boolean
  currentProduct?: ProductType
  activeProducts: Product[]
}

const EndIcon = () => (
  <FontAwesomeIcon
    icon={faCircleCheck}
    data-testid="checkmark"
    style={{ height: '16px', width: '16px' }}
    color="green"
  />
)

const BlockIcon = () => (
  <FontAwesomeIcon
    icon={faLock}
    data-testid="lock"
    style={{ height: '16px', width: '14px' }}
    color="grey"
  />
)

export const SidePanelWrapperV2: React.FC<SidePanelWrapperProps> = ({
  activeProducts,
  currentProduct,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { flag: isAgreementBasedOfferEnabled } = useFlag('agreementBasedOffer')
  const { flag: isFlyoutOverviewEnabled } = useFlag('flyoutOverview')
  const { flag: isMarketplaceSeoEnabled } = useFlag('marketplaceSeo')
  const { flag: isFlyoutStorefrontEnabled } = useFlag('flyoutStorefront')
  const { flag: isPropensityEnabled } = useFlag('propensityFeature')

  const { loading: isFlagCosellLoading, value: cosellEnabled } =
    useFlagValue('cosell')
  const isCosellEnabled = !isFlagCosellLoading && cosellEnabled
  const { logout: auth0Logout } = useAuth0()
  const {
    onboardingComplete,
    hasCompanyInfo,
    loading: loadingOnboardingInfo,
    isNewPartner,
    hasArnRoleSetup,
    hasPolicySetup,
    hasConnectedCrmAtLeastOnce,
    hasSolutionsAndServices,
  } = useOppSyncOnboardingStatus(isCosellEnabled)

  const { partnerData, flyoutSellerInfo, isLoadingSeller } = useSelector<{
    flyoutSellerInfo: FlyOutListingData
    partnerData: undefined | PartnerData
    isLoadingSeller: boolean
  }>(state => ({
    flyoutSellerInfo: state.flyoutSellerInfo,
    partnerData: state.PartnerData.user.partnerData || undefined,
    isLoadingSeller:
      isLoading(state.loading)(LoadingTypes.MARKETPLACE_INFO) ||
      isLoading(state.loading)(LoadingTypes.FLYOUT_ONBOARDING_DATA),
  }))

  const loadingFlyoutSellerInfo = Boolean(!partnerData || isLoadingSeller)
  const activeProductsFromUser = useSelector<ActiveProducts | undefined>(
    state => state.PartnerData.user.partnerData?.activeProducts
  )
  const { userProfile } = useSelector<UserProfileState>(
    state => state.userProfile
  )
  const flyOutOnboardingForm = useSelector<Sections>(
    state => state.flyOutOnboarding.onboardingForm
  )
  const crmId = useSelector<string | undefined>(
    state => state?.PartnerData.user.partnerData?.crmId
  )
  const { isNewCosellPipeline } = useCrmAuthInfo(crmId)
  const { crmType } = customerFormData()
  const partnerInviteEnabled = useFlagValue('partnerInvite')
  const isCrmLabra = crmType === 'labra'
  const showCrm = partnerInviteEnabled?.value ? isCrmLabra : false
  const isFlyoutOnboarded = flyoutSellerInfo?.onboarded === true
  const isUserManaged = flyOutOnboardingForm?.labraPartnerDetails?.isManaged

  const globalState = useSelector(state => state)
  const logout = () => {
    try {
      localStorage.removeItem('onboardingWelcome')
    } catch (err) {
      console.warn('localStorage is not available.')
      errorLogger({ globalState })(err as Error)
      return undefined
    }

    localStorage.removeItem('auth0-access-token')
    auth0Logout({ logoutParams: { returnTo: window.location.origin } })
  }

  const product = currentProduct || activeProducts[0]

  const footerSubMenuRoutes: NavigationRoutesType[] = [
    {
      key: 7,
      path: '/user/profile',
      name: 'Account information',
      disabled: false,
    },

    {
      key: 9,
      path: undefined,
      name: 'Logout',
      onClick: logout,
      disabled: false,
    },
  ]

  const user = useSelector<UserProfile | undefined>(
    state => state.userProfile.userProfile
  )

  const isAdmin =
    user?.roles?.some(r =>
      [Role.ADMIN, Role.SUPER_ADMIN, Role.ACCOUNT_EXECUTIVE].includes(r)
    ) || false

  const navigateRoute = (path: string) => {
    if (path) {
      navigate(path)
    }
  }

  const productSelector = {
    active: product,
    options: productSelectorDataV2.options,
  }

  const showProductSelectorAndOptions = useMemo(() => {
    if (isAdmin || activeProducts.length === 0) {
      return false
    } else {
      return true
    }
  }, [activeProducts, isAdmin])

  const handleProductChange = (product?: string) => {
    if (product) {
      if (!activeProductsFromUser?.includes(product as Product)) {
        navigate('/product-selection')
      } else {
        navigate(`/${product}`)
      }
    }
  }

  const getIcon = (showCrm: boolean, hasConnectedCrmAtLeastOnce: boolean) => {
    if (showCrm) {
      return BlockIcon
    }
    if (hasConnectedCrmAtLeastOnce) {
      return EndIcon
    }
    return undefined
  }

  const getOppSyncOnboardingRoutes = () => {
    const oppSyncOnboardingRoutesFiltered = {
      ...OppSyncOnboardingRoutes,

      routes: OppSyncOnboardingRoutes.routes.map(route => {
        if (hasCompanyInfo) {
          if (route.name === 'Cloud settings') {
            return {
              ...route,
              disabled: false,
              endIcon:
                hasArnRoleSetup && hasPolicySetup && hasSolutionsAndServices
                  ? EndIcon
                  : undefined,
            }
          }
          if (route.name === 'CRM settings') {
            return {
              ...route,
              disabled: false,
              endIcon: getIcon(showCrm, hasConnectedCrmAtLeastOnce),
            }
          }
          if (route.name === 'Company details') {
            return { ...route, disabled: false, endIcon: EndIcon }
          }
          return { ...route, disabled: false }
        } else {
          if (route.name === 'CRM settings') {
            return {
              ...route,
              disabled: true,
              endIcon: getIcon(showCrm, hasConnectedCrmAtLeastOnce),
            }
          } else if (route.name === 'Cloud settings') {
            return {
              ...route,
              disabled: true,
              endIcon:
                hasArnRoleSetup && hasPolicySetup && hasSolutionsAndServices
                  ? EndIcon
                  : undefined,
            }
          }
          return route
        }
      }),
    }
    return oppSyncOnboardingRoutesFiltered
  }

  const getFlyoutOnboardingRoutes = () => {
    const flyoutOnboardingRoutesFiltered = {
      ...FlyoutOnboardingRoutes,
      miscellaneousRoutes: [
        {
          key: 1,
          path: '/flyout/onboarding/aws-requirements',
          name: 'Check AWS requirements',
          disabled: false,
        },
      ],
      routes: FlyoutOnboardingRoutes.routes
        .filter(route =>
          isUserManaged ? !route.path?.includes('cloud-settings') : true
        )
        .map(route => {
          const isOnboardingFormCompleted = getIsFormCompleted(
            route.path || '',
            flyOutOnboardingForm
          )
          const onboardingRoute = {
            ...route,
            ...(isOnboardingFormCompleted
              ? {
                  endIcon: EndIcon,
                }
              : {}),
          }

          return onboardingRoute
        }),
    }
    return flyoutOnboardingRoutesFiltered
  }
  const { pathname } = useLocation()
  const isOnboardingRoute = pathname.split('/').includes('onboarding')
  const isOppsyncOnboardingRoute =
    pathname.split('/').includes('onboarding') &&
    pathname.split('/').includes('oppsync')
  const customerData = customerFormData()
  const { OPPSYNC: oppsyncProduct } = customerData.subscribedProducts
  const isOppsyncSubscribed = !partnerInviteEnabled?.value || !!oppsyncProduct
  const { FLYOUT: flyoutProduct } = customerData.subscribedProducts
  const isFlyoutSubscribed = !partnerInviteEnabled?.value || !!flyoutProduct
  const isProductSelectorRoute = pathname
    .split('/')
    .includes('product-selection')
  const finalRoutes: {
    title: string
    routes: NavigationRoutesType[]
    miscellaneousRoutes?: NavigationRoutesType[]
  } = useMemo(() => {
    if (isAdmin || isProductSelectorRoute) {
      return { title: '', routes: [] }
    }
    if (product == 'oppsync') {
      if (!isOppsyncSubscribed) {
        return { title: '', routes: [] }
      }
    }
    if (product == 'flyout') {
      if (!isFlyoutSubscribed) {
        return { title: '', routes: [] }
      }
    }
    if (product == 'oppsync' && activeProductsFromUser?.includes('oppsync')) {
      if (!isOnboardingRoute && (onboardingComplete || !isNewPartner)) {
        const updatedOppsyncRoutes =
          isCosellEnabled && isNewCosellPipeline
            ? [
                ...OppSyncNavigationRoutesCosell,
                ...(isPropensityEnabled?.value ? OppsyncPropensityRoute : []),
              ]
            : [
                ...OppSyncNavigationRoutes,
                ...(isPropensityEnabled?.value ? OppsyncPropensityRoute : []),
              ]
        return {
          title: '',
          routes: updatedOppsyncRoutes,
        }
      } else {
        return getOppSyncOnboardingRoutes()
      }
    }
    if (product == 'flyout' && activeProductsFromUser?.includes('flyout')) {
      const routeWithSubscription = [
        ...(isFlyoutOverviewEnabled?.value ? FlyoutOverviewRoute : []),
        ...(isFlyoutStorefrontEnabled?.value ? FlyoutStorefrontRoute : []),
        ...FlyOutNavigationRoutes,
        ...(isMarketplaceSeoEnabled?.value ? marketplaceSeo : []),
        ...(isAgreementBasedOfferEnabled?.value
          ? [
              {
                key: 4,
                path: '/flyout/subscriptions',
                name: 'Subscriptions',
                icon: () => (
                  <FontAwesomeIcon
                    icon={faFileContract}
                    data-testid="subscription-icon"
                    style={{ height: '16px', width: '24px' }}
                  />
                ),
                disabled: false,
              },
            ]
          : []),
      ].sort(
        (
          navigateRouteOne: NavigationRoutesType,
          navigateRouteTwo: NavigationRoutesType
        ) => navigateRouteOne.key - navigateRouteTwo.key
      )
      if (isFlyoutOnboarded) {
        return { title: '', routes: routeWithSubscription }
      } else {
        return getFlyoutOnboardingRoutes()
      }
    }
    return { title: '', routes: [] }
  }, [
    product,
    onboardingComplete,
    hasCompanyInfo,
    activeProductsFromUser?.length,
    flyOutOnboardingForm,
    isOnboardingRoute,
    isProductSelectorRoute,
    loadingOnboardingInfo,
    hasSolutionsAndServices,
    isAgreementBasedOfferEnabled,
    hasConnectedCrmAtLeastOnce,
    loadingFlyoutSellerInfo,
    isFlyoutOverviewEnabled,
    isFlyoutStorefrontEnabled,
  ])
  const username = `${userProfile?.givenName || ''} ${
    userProfile?.familyName || ''
  }`.trim()

  const getCompletePercentage = (obj: Record<string, boolean>) => {
    if (!obj) {
      return 0
    }
    const pageMarkerLength = Object.keys(obj).length
    const pagesCompletedLength = Object.values(obj).filter(p => !!p).length
    const percentage = (pagesCompletedLength / pageMarkerLength) * 100
    return Math.round(percentage)
  }

  const obj = {
    hasCompanyInfo,
    hasArnRoleSetup,
    hasPolicySetup,
    hasSolutionsAndServices,
    ...(showCrm === false && { hasConnectedCrmAtLeastOnce }),
  }

  const completePercentage = useMemo(() => getCompletePercentage(obj), [obj])

  return (
    <SidePanelV2
      activeLink={location.pathname}
      title={finalRoutes.title || ''}
      {...(isOppsyncOnboardingRoute && isOppsyncSubscribed
        ? { showProgress: true }
        : false)}
      {...(isOppsyncOnboardingRoute && isOppsyncSubscribed
        ? { progressCount: completePercentage }
        : {})}
      routes={finalRoutes.routes}
      navigateRoute={navigateRoute}
      middleSection={TeamMembersWrapper}
      footerSubMenuRoutes={footerSubMenuRoutes}
      productSelectorData={productSelector}
      onProductChange={handleProductChange}
      showProductSelector={showProductSelectorAndOptions}
      userInfo={{
        name: username === '' ? '-' : username,
        roles: userProfile.roles?.map(snakeToSentenceCase) || [],
      }}
      openDrawer={isOnboardingRoute ? isOnboardingRoute : undefined}
      miscellaneousRoutes={finalRoutes.miscellaneousRoutes}
    />
  )
}

export const getIsFormCompleted = (
  path: string,
  flyOutOnboardingForm: Sections
) => {
  const pageMarker =
    flyOutOnboardingForm[FlyoutOnboardingSectionName.PAGE_MARKER]
  if (path.includes('company-information')) {
    return !!pageMarker?.page1
  } else if (path.includes('cloud-settings')) {
    return !!pageMarker?.page2
  } else {
    return false
  }
}
