import { Reducer } from 'redux'
import { MarketplaceSeoActionTypes } from './action'
import { CloudMarketplace } from '../../../flyout/modules/productsListing/reducer'

export type KeywordType = {
  _id?: string
  name: string
  status?: string
  createdAt?: string
  updatedAt?: string
  nextUpdateScheduledAt?: string
  cloud?: CloudMarketplace
  competitors?: string[]
}

export type KeywordTypePayload = {
  _id?: string
  keyword: string
  status?: string
  createdAt?: string
  updatedAt?: string
  nextUpdateScheduledAt?: string
  cloud: CloudMarketplace
  competitors?: string[]
}

export type CompetitorType = {
  _id: string
  createdAt?: string
  updatedAt?: string
  name: string
  partnerId?: string
  cloud?: CloudMarketplace
  isProfileActive?: boolean
  status?: string
  cloudSellerId?: string
  self?: boolean
}

export type KeywordCompetitorMapping = {
  keywords: KeywordType[]
  competitors: CompetitorType[]
}

export interface KeywordItem {
  keywordName: string
  updatedAt: string
  competitors: CompetitorDatum[]
  selfLatestRank: number
  selfInitialRank: number
  selfRankChange: number
}

export interface CompetitorDatum {
  Id: string
  name: string
  data: DatumDatum[]
  rankChange: number
  rank: number
  initialRank: number
}

export interface DatumDatum {
  createdAt: string
  rank: number
  productName?: string
  listingUrl?: string
}

export type MpsSeoState = {
  keywords: KeywordType[]
  competitors: CompetitorType[]
  keywordCompetitorMapping: KeywordCompetitorMapping[]
  listingUrl?: string
  keywordRanking: KeywordItem[]
}

export type CompetitorPayload = {
  name: string
  cloud: CloudMarketplace
  partnerId: string
  cloudSellerId: string
  self?: boolean
}

export const initialState: MpsSeoState = {
  keywords: [],
  competitors: [],
  keywordCompetitorMapping: [],
  listingUrl: '',
  keywordRanking: [],
}

type actionType =
  | {
      type: MarketplaceSeoActionTypes.SET_DEFAULT_COMPETITOR
      payload: { competitors: CompetitorType[] }
    }
  | {
      type: MarketplaceSeoActionTypes.SET_DEFAULT_KEYWORDS
      payload: { keywords: KeywordType[] }
    }
  | {
      type: MarketplaceSeoActionTypes.SET_KEYWORD_COMPETITOR_MAPPING
      payload: { keywordCompetitorMapping: KeywordCompetitorMapping[] }
    }
  | {
      type: MarketplaceSeoActionTypes.SET_SEARCH_RANKING
      payload: { keywordRanking: KeywordItem[] }
    }
  | {
    type: MarketplaceSeoActionTypes.SET_SELF_LISTING_URL,
    payload: { listingUrl: '' }
  }

export const reducer: Reducer<MpsSeoState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case MarketplaceSeoActionTypes.SET_DEFAULT_COMPETITOR: {
      return {
        ...state,
        competitors: action.payload.competitors,
      }
    }

    case MarketplaceSeoActionTypes.SET_DEFAULT_KEYWORDS: {
      return {
        ...state,
        keywords: action.payload.keywords,
      }
    }

    case MarketplaceSeoActionTypes.SET_KEYWORD_COMPETITOR_MAPPING: {
      return {
        ...state,
        keywordCompetitorMapping: action.payload.keywordCompetitorMapping,
      }
    }

    case MarketplaceSeoActionTypes.SET_SEARCH_RANKING: {
      return {
        ...state,
        keywordRanking: action.payload.keywordRanking,
      }
    }

    case MarketplaceSeoActionTypes.SET_SELF_LISTING_URL: {
      return {
        ...state,
        listingUrl: action.payload.listingUrl,
      }
    } 

    default: {
      return state
    }
  }
}
