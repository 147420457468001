import { AnyAction } from 'redux'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import {
  DefaultSuccesrOnSubmitData,
  RequestFailureMessage,
} from '../../../common/utils/messagesContants'
import { AxiosResponse } from 'axios'
import { camelize } from 'casing'
// import { getReports } from '../../api/markeplace'
import { getErrorMessages } from '../../../common/utils/error'
import { PropensityPayload } from './reducer'
import { actionTypeWrapper } from '../../../common/utils/actionTypeWrapper'
import { AppDispatch, RootState } from '../../../store'
import { snakeCase } from 'lodash'
import { errorLogger } from '../../../common/utils/errorLogger'
import FileDownload from 'js-file-download'
import { useCurrentCloudProvider } from '../../../common/utils/Hooks/useCurrentCloudProvider'
import propensityMockData from '../../../mocks/propensityMockData.json'
import { fetchPropensityData, postPtb } from '../../api'
import { LastEvaluatedObject } from '../../../flyout/modules/revenueInsightsV2/reducer'
import { convertObjectKeysAndValuesToCommaSeprated } from '../../../common/utils/helperFunctions'
import { newrelicErrLogger } from '../../../common/utils/ErrorHandler'

export enum PropensityActionTypes {
  SET_REPORTS_DATA = 'SET_REPORTS_DATA',
  PROPENSITY_SET_PAGE_NUMBER = 'PROPENSITY_SET_PAGE_NUMBER',
  PROPENSITY_SET_LIMIT = 'PROPENSITY_SET_LIMIT',
  SET_PRODUCT_ID = 'SET_PRODUCT_ID',
  CLEAR_PROPENSITY_REPORTS = 'CLEAR_PROPENSITY_REPORTS',
}

export const getPropensityData =
  (
    pageSizeValue?: number,
    pageNumber?: number,
    lastEvaluatedObject?: LastEvaluatedObject | null
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))

    try {
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      const { pageSize: pageSizeStateValue } = getState().propensity
      const pageSize = pageSizeValue ? pageSizeValue : pageSizeStateValue

      const { keys: lastEvaluatedKeys, values: lastEvaluatedValues } =
        convertObjectKeysAndValuesToCommaSeprated(lastEvaluatedObject)
      const { data } = await fetchPropensityData(
        partnerId,
        pageSize,
        lastEvaluatedKeys,
        lastEvaluatedValues
      )
      const data2 = propensityMockData
      await dispatch(setReportsData(camelize(data), pageSize))
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const postPtbCall =
  (partnerId: string, s3Path: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))

    try {
      const { data } = await postPtb(partnerId, s3Path)
      dispatch(
        updateAppAlert({
          message: DefaultSuccesrOnSubmitData,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
      return data
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      newrelicErrLogger(error as Error, {
        message: error,
      })
    } finally {
      await dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const setReportsData = (data: PropensityPayload, pageSize: number) => ({
  type: PropensityActionTypes.SET_REPORTS_DATA,
  payload: { ...data, pageSize },
})

export const updateRevenuePageSize =
  (pageSize: number) => async (dispatch: AppDispatch) => {
    await dispatch(setPropensityPageSize(pageSize))
    await dispatch(getPropensityData() as unknown as AnyAction)
  }

export const setPropensityPageSize = (pageSize: number) => ({
  type: PropensityActionTypes.PROPENSITY_SET_LIMIT,
  payload: pageSize,
})

export const setPropensityPageNumber = (pageNumber: number) => ({
  type: PropensityActionTypes.PROPENSITY_SET_PAGE_NUMBER,
  payload: pageNumber,
})

export const clearPropensityReports = () => ({
  type: PropensityActionTypes.CLEAR_PROPENSITY_REPORTS,
})

export const setPageOneForPropensity = () => async (dispatch: AppDispatch) => {
  await dispatch(setPropensityPageNumber(1))
}
