import { Reducer } from 'redux'
import { DateTime } from 'luxon'
import { PropensityActionTypes } from './action'
import { LastEvaluatedObject } from '../../../flyout/modules/revenueInsightsV2/reducer'

export enum ScoreStatus {
  LOW = 'Low',
  MEDIUM = 'Mid',
  HIGH = 'High',
}
export interface PropensityRow {
  inferenceBatchId: string
  domainName: string
  AWS: string
  AZURE: string
  GCP: string
  partnerId: string
  PROPENSITY: string
  prospect: string
  seller: string
  timestamp: string
  companyName: string
  industry: string
}

export interface PropensityData {
  records: PropensityRow[]
  pageSize: number
  pageNumber: number
  noOfPage: number | null
  lastEvaluatedObject?: LastEvaluatedObject | null
  count?: number
}
export interface PropensityPayload {
  records: PropensityRow[]
  count: number
  pageSize: number
}
type actionType =
  | {
      type: PropensityActionTypes.SET_REPORTS_DATA
      payload: PropensityPayload
    }
  | {
      type: PropensityActionTypes.PROPENSITY_SET_PAGE_NUMBER
      payload: number
    }
  | {
      type: PropensityActionTypes.PROPENSITY_SET_LIMIT
      payload: number
    }
  | {
      type: PropensityActionTypes.CLEAR_PROPENSITY_REPORTS
    }

export const initialState: PropensityData = {
  records: [],
  pageSize: 10,
  pageNumber: 1,
  noOfPage: null,
  lastEvaluatedObject: null,
  count: 0,
}
export const reducer: Reducer<PropensityData, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PropensityActionTypes.SET_REPORTS_DATA: {
      return {
        ...state,
        ...action.payload,
        records: [...state.records, ...action.payload.records],
        noOfPage: Math.ceil(action.payload.count / action.payload.pageSize),
      }
    }
    case PropensityActionTypes.PROPENSITY_SET_LIMIT: {
      return {
        ...state,
        pageSize: action.payload,
      }
    }
    case PropensityActionTypes.PROPENSITY_SET_PAGE_NUMBER: {
      return {
        ...state,
        pageNumber: action.payload,
      }
    }
    case PropensityActionTypes.CLEAR_PROPENSITY_REPORTS: {
      return {
        ...state,
        records: [],
        count: 0,
        lastEvaluatedObject: null,
      }
    }
    default: {
      return state
    }
  }
}
