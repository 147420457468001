import { Reducer } from 'redux'
import { StorefrontActionTypes } from './action'
import { CloudType } from '../../../common/modules/types'

export interface Template {
  name?: string
  cloud?: string
  usedAt?: string
  lastUpdateInDays?: string
  id?: string
}
export interface Integrations {
  id: string,
	name: string,
	createdAt: string,
	updatedAt: string,
	lastUpdateInDays: string,
	cloud : CloudType,
}

export interface StorefrontState {
  templates: Template[]
  integrations: Integrations[]
}

type ActionType = {
  type: StorefrontActionTypes.SET_TEMPLATES_DATA
  payload: Template[]
}
|
{
  type: StorefrontActionTypes.SET_INTEGRATIONS_DATA
  payload: Integrations[]
}
const initialState: StorefrontState = {
  templates: [],
  integrations:[],
}

export const reducer: Reducer<StorefrontState, ActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case StorefrontActionTypes.SET_TEMPLATES_DATA: {
      return { ...state, templates: action.payload }
    }
    case StorefrontActionTypes.SET_INTEGRATIONS_DATA: {
      return { ...state, integrations: action.payload }
    }
    default: {
      return state
    }
  }
}
