import { AxiosResponse } from 'axios'
import { API } from '../../common/api'
import {
  azureEngineServiceUrl,
  cloudAccountServiceUrl,
  crmServiceUrl,
  labravAWSEngineServiceUrl,
  marketplaceServiceUrl,
} from '../../common/utils/constants'
import {
  AzureAccountDetails,
  AzureBackendAccountDetails,
} from '../modules/productAccount/reducer'
import { Account } from '../../common/modules/cmcm/types'
import { SegmentAndGreenField } from '../../common/modules/CloudAccounts/reducer'

const mapAzBE2FE: (
  a: AzureBackendAccountDetails
) => AzureAccountDetails = details => ({
  id: details.id,
  name: details.company_name,
  MPNID: details.mpn_id,
  industries: details.industry,
  microsoftPartnerType: details.partner_type,
  ISV: details.is_isv,
  grantConsent: true,
  terms: true,
})

export const fetchAzureAccountDetails = (parnterId: string) =>
  API.get(`${azureEngineServiceUrl}/partners/${parnterId}`)
    .then(({ data }) => data as AzureBackendAccountDetails)
    .then(mapAzBE2FE)

export const deactivateAzureAccount = (
  partnerId: string,
  accountId: string,
  payload: Record<string, unknown>
) => {
  return API.put(
    `${cloudAccountServiceUrl}/azure/organizations/${partnerId}/accounts/${accountId}`,
    payload
  )
}

export const fetchCRMConfiguration = (crmId: string) =>
  API.get(`${crmServiceUrl}/crms/${crmId}`)

export const fetchSegmentAndGreenField = (
  partnerId: string
): Promise<AxiosResponse<SegmentAndGreenField>> =>
  API.get(`${labravAWSEngineServiceUrl}/partners/${partnerId}/segment_reports`)

export const getPreSignedUrlOppSync = (
  partnerId: string,
  path: string,
  bucketName?: string
) => {
  return API.get(`${labravAWSEngineServiceUrl}/partners/${partnerId}/assets`, {
    params: {
      fields: 'aws_presigned_url',
      assets: path,
      operation_method: 'POST',
      expires_in: 3600,
      bucket_name: bucketName ? bucketName : '',
    },
  })
}

export const postAssetsOppSync = (
  partnerId: string,
  assets: Record<string, unknown>
) => {
  return API.post(
    `${labravAWSEngineServiceUrl}/partners/${partnerId}/assets`,
    assets
  )
}

export const fetchPropensityData = (
  partnerId?: string,
  pageSize?: number,
  lastEvaluatedKeys?: string,
  lastEvaluatedValues?: string
) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/ptb_reports`,
    {
      params: {
        page_size: pageSize,
        last_evaluated_keys: lastEvaluatedKeys,
        last_evaluated_values: lastEvaluatedValues,
      },
    }
  )
}

export const postPtb = (partnerId: string, s3Path: string) => {
  return API.post(
    `${marketplaceServiceUrl}/partners/${partnerId}/calculate_ptb?s3_path=${s3Path}`
  )
}
