import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { AppDispatch, RootState } from '../../../store'
import { getErrorMessages } from '../../utils/error'
import { RequestFailureMessage } from '../../utils/messagesContants'
import { AxiosResponse } from 'axios'
import { errorLogger } from '../../utils/errorLogger'
import {
  CompetitorPayload,
  CompetitorType,
  KeywordCompetitorMapping,
  KeywordItem,
  KeywordType,
  KeywordTypePayload,
} from './reducer'
import {
  fetchCompetitorName,
  saveCompetitorName,
  fetchCompetitorsList,
  fetchKeywordsList,
  fetchKeywordCompetitorMapping,
  saveKeywordRecord,
  updateKeywordRecord,
  searchKeywordsRankingList,
  deleteCompetitor,
  fetchSelfListingUrl,
  startRankingProcess,
} from '../../api/marketplaceSeo'
import { CloudMarketplace } from '../../../flyout/modules/productsListing/reducer'
import { actionTypeWrapper } from '../../utils/actionTypeWrapper'
import { camelize, snakeize } from 'casing'

export enum MarketplaceSeoActionTypes {
  SET_DEFAULT_KEYWORDS = 'SET_DEFAULT_KEYWORDS',
  SET_DEFAULT_COMPETITOR = 'SET_DEFAULT_COMPETITOR',
  SET_KEYWORD_COMPETITOR_MAPPING = 'SET_KEYWORD_COMPETITOR_MAPPING',
  SET_SEARCH_RANKING = 'SET_SEARCH_RANKING',
  SET_SELF_LISTING_URL = 'SET_SELF_LISTING_URL',
}

export const setDefaultCompetitors = (competitors: CompetitorType[]) => ({
  type: MarketplaceSeoActionTypes.SET_DEFAULT_COMPETITOR,
  payload: {
    competitors,
  },
})

export const setDefaultKeywords = (keywords: KeywordType[]) => ({
  type: MarketplaceSeoActionTypes.SET_DEFAULT_KEYWORDS,
  payload: {
    keywords,
  },
})

export const setSelfListingUrl = (listingUrl: string) => ({
  type: MarketplaceSeoActionTypes.SET_SELF_LISTING_URL,
  payload: {
    listingUrl,
  },
})

export const setSearchRankingResult = (keywordRanking: KeywordItem[]) => ({
  type: MarketplaceSeoActionTypes.SET_SEARCH_RANKING,
  payload: {
    keywordRanking,
  },
})

export const setKeywordCompetitorMapping = (
  keywordCompetitorMapping: KeywordCompetitorMapping[]
) => ({
  type: MarketplaceSeoActionTypes.SET_KEYWORD_COMPETITOR_MAPPING,
  payload: {
    keywordCompetitorMapping,
  },
})

export const getCompetitors =
  (partnerId: string, cloudMarketplace: CloudMarketplace) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const response = await fetchCompetitorsList(partnerId, cloudMarketplace)
      if (response?.data) {
        dispatch(
          actionTypeWrapper(
            cloudMarketplace,
            setDefaultCompetitors(response?.data?.records)
          )
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const getKeywordCompetitorMapping =
  (
    partnerId: string,
    cloudMarketplace: CloudMarketplace,
    hideLoading?: boolean
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    !hideLoading &&
      dispatch(startLoading(LoadingTypes.FETCH_KEYWORD_COMPETITOR_MAPPING))
    try {
      const response = await fetchKeywordCompetitorMapping(
        partnerId,
        cloudMarketplace
      )
      if (response?.data) {
        dispatch(
          actionTypeWrapper(
            cloudMarketplace,
            setKeywordCompetitorMapping(response?.data?.records)
          )
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      !hideLoading &&
        dispatch(stopLoading(LoadingTypes.FETCH_KEYWORD_COMPETITOR_MAPPING))
    }
  }

export const getKeywords =
  (partnerId: string, cloudMarketplace: CloudMarketplace) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const response = await fetchKeywordsList(partnerId, cloudMarketplace)
      if (response?.data) {
        dispatch(
          actionTypeWrapper(
            cloudMarketplace,
            setDefaultKeywords(response?.data?.keywords || [])
          )
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const getSelfListingUrl =
  (partnerId: string, cloudMarketplace: CloudMarketplace) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const response = await fetchSelfListingUrl(partnerId)
      if (response?.data) {
        dispatch(
          actionTypeWrapper(
            cloudMarketplace,
            setSelfListingUrl(response?.data?.marketplace_url || '')
          )
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const fetchAndSaveCompetitor =
  ({
    partnerId,
    productId,
    cloudMarketplace,
    callBackCompetitor,
    self = false,
  }: {
    partnerId: string
    productId: string
    cloudMarketplace: CloudMarketplace
    self?: boolean
    callBackCompetitor?: ({
      success,
      error,
    }: {
      success?: true
      error?: string
    }) => void
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.SAVE_COMPETITOR))
    try {
      const existingCompetitor = getState().marketplaceSeo[
        cloudMarketplace
      ].competitors?.map(item => item?.name)

      const response = await fetchCompetitorName(productId)

      if (
        response?.data &&
        !existingCompetitor.includes(response?.data?.displayName)
      ) {
        const competitorDetail: CompetitorPayload = {
          name: response?.data?.displayName,
          cloud: cloudMarketplace,
          partnerId: partnerId,
          cloudSellerId: response?.data?.value,
          ...(self ? { self: true } : {}),
        }

        await dispatch(
          saveCompetitor({
            partnerId,
            competitors: [competitorDetail],
            cloudMarketplace,
            callBackCompetitor,
          })
        )
      } else if (existingCompetitor.includes(response?.data?.displayName)) {
        callBackCompetitor &&
          callBackCompetitor({
            error: `${response?.data?.displayName} already added.`,
          })
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorMsg = getErrorMessages([RequestFailureMessage])(
        error.response as AxiosResponse<ErrorResponse>
      )
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      callBackCompetitor && callBackCompetitor({ error: errorMsg })
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.SAVE_COMPETITOR))
    }
  }

export const saveCompetitor =
  ({
    partnerId,
    competitors,
    cloudMarketplace,
    callBackCompetitor,
  }: {
    partnerId: string
    competitors: CompetitorPayload[]
    cloudMarketplace: CloudMarketplace
    callBackCompetitor?: ({
      success,
      error,
    }: {
      success?: true
      error?: string
    }) => void
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.SAVE_COMPETITOR))
    try {
      await saveCompetitorName(
        partnerId,
        snakeize({
          competitors: [...competitors],
        })
      )
      callBackCompetitor && callBackCompetitor({ success: true })
      await dispatch(getCompetitors(partnerId, cloudMarketplace))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.SAVE_COMPETITOR))
    }
  }

export const saveKeyword =
  ({
    partnerId,
    keywords,
  }: {
    partnerId: string
    keywords: KeywordTypePayload[]
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.SAVE_COMPETITOR))
    try {
      await saveKeywordRecord(
        partnerId,
        snakeize({
          keywords: [...keywords],
        })
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.SAVE_COMPETITOR))
    }
  }

export const updateKeyword =
  ({
    partnerId,
    keywords,
  }: {
    partnerId: string
    keywords: KeywordTypePayload[]
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.SAVE_COMPETITOR))
    try {
      await updateKeywordRecord(
        partnerId,
        snakeize({
          keywords: [...keywords],
        })
      )

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.SAVE_COMPETITOR))
    }
  }

export const addEditKeywordSection =
  ({
    existingKeywordsCompetitors,
    updatedKeywordCompetitorMapping,
    partnerId,
    cloudMarketplace,
    callBack,
  }: {
    existingKeywordsCompetitors?: KeywordCompetitorMapping
    updatedKeywordCompetitorMapping: KeywordCompetitorMapping
    partnerId: string
    cloudMarketplace: CloudMarketplace
    callBack?: () => void
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const selectedCompetitors = updatedKeywordCompetitorMapping?.competitors

    const existingKeywordsList = getState().marketplaceSeo[
      cloudMarketplace
    ].keywords?.map(item => item?._id)

    const updatedKeywordIds = updatedKeywordCompetitorMapping?.keywords?.map(
      item => item?._id
    )

    const removedKeywords = existingKeywordsCompetitors?.keywords.filter(
      item => !updatedKeywordIds?.includes(item?._id)
    )

    const removedKeywordsIds = removedKeywords?.map(item => item._id)

    const existingKeywords = updatedKeywordCompetitorMapping?.keywords?.filter(
      keywordItem =>
        keywordItem?._id && existingKeywordsList.includes(keywordItem?._id) &&
        !removedKeywordsIds?.includes(keywordItem._id)
    )
    const newKeywords = updatedKeywordCompetitorMapping?.keywords?.filter(
      keywordItem => !keywordItem?._id
    )

    if (existingKeywords.length) {
      const payload = existingKeywords?.map(
        ({ competitors, name, ...item }) => ({
          ...item,
          keyword: name,
          cloud: cloudMarketplace,
          partnerId: partnerId,
          competitors: selectedCompetitors?.map(
            itemCompetitor => itemCompetitor._id
          ),
        })
      )
      await dispatch(updateKeyword({ partnerId, keywords: payload }))
    }

    if (newKeywords.length) {
      const payload = newKeywords?.map(({ competitors, name, ...item }) => ({
        ...item,
        keyword: name,
        cloud: cloudMarketplace,
        partnerId: partnerId,
        competitors: selectedCompetitors?.map(
          itemCompetitor => itemCompetitor._id
        ),
      }))
      await dispatch(saveKeyword({ partnerId, keywords: payload }))
    }

    if (removedKeywords?.length) {
      const payload = removedKeywords?.map(
        ({ competitors, name, ...item }) => ({
          ...item,
          keyword: name,
          cloud: cloudMarketplace,
          partnerId: partnerId,
          competitors: [],
        })
      )
      await dispatch(updateKeyword({ partnerId, keywords: payload }))
    }

    await dispatch(getKeywords(partnerId, cloudMarketplace))
    await dispatch(
      getKeywordCompetitorMapping(partnerId, cloudMarketplace, true)
    )

    await dispatch(startRankingProcessData(partnerId, cloudMarketplace))

    if (callBack) {
      await callBack()
    }
  }

export const removeKeywordCompetitor =
  ({
    keywordCompetitor,
    partnerId,
    cloudMarketplace,
  }: {
    keywordCompetitor: KeywordCompetitorMapping
    partnerId: string
    cloudMarketplace: CloudMarketplace
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const filterExistingKeyword = keywordCompetitor?.keywords?.filter(
      item => item?._id
    )
    if (filterExistingKeyword?.length) {
      const payload = filterExistingKeyword?.map(
        ({ competitors, name, ...item }) => ({
          ...item,
          keyword: name,
          cloud: cloudMarketplace,
          partnerId: partnerId,
          competitors: [],
        })
      )
      await dispatch(updateKeyword({ partnerId, keywords: payload }))
      await dispatch(
        getKeywordCompetitorMapping(partnerId, cloudMarketplace, true)
      )
      await dispatch(startRankingProcessData(partnerId, cloudMarketplace))
    }
  }

export const searchRanking =
  (partnerId: string, cloudMarketplace: CloudMarketplace) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.SEARCH_SEO_RANKING))
    try {
      const response = await searchKeywordsRankingList(
        partnerId,
        cloudMarketplace
      )
      if (response?.data) {
        dispatch(
          actionTypeWrapper(
            cloudMarketplace,
            setSearchRankingResult(camelize(response?.data?.records) as KeywordItem[])
          )
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.SEARCH_SEO_RANKING))
    }
  }

export const deleteCompetitorData =
  (
    partnerId: string,
    competitorId: string,
    competitorName: string,
    cloudMarketplace: CloudMarketplace
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      await deleteCompetitor(partnerId, competitorId)
      await dispatch(getCompetitors(partnerId, cloudMarketplace))
      await dispatch(
        updateAppAlert({
          message: `${competitorName} deleted successfully!.`,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const startRankingProcessData =
  (partnerId: string, cloudMarketplace: CloudMarketplace) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      await startRankingProcess(partnerId, cloudMarketplace)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.SEARCH_SEO_RANKING))
    }
  }
