import { PlanType } from '@labrav/react-components'
import {
  CloudMarketplace,
  Product,
  ProductStatus,
} from '../productsListing/reducer'
import { FlyoutFunction, notAllowedProductSelection } from './reducer'
import { AppDispatch, RootState } from '../../../store'
import { AnyAction } from 'redux'
import {
  clearResaleAuthorizations,
  setResaleAuthorizationsPageNumber,
} from '../../../admin/modules/resaleAuthorizations/actions'
import {
  clearAllRevenueData,
  setPageOneForRevenue,
} from '../revenueInsights/actions'
import {
  clearRevenueReports,
  setPageOneForRevenue as setPageOneForRevenueV2,
} from '../revenueInsightsV2/actions'
import { setPageNumber as setPageNumberForPrivateOffers } from '../privateOffer/actions'
import { clearPrivateOffers } from '../privateOffer/actions'
import { setSelectedProductOnListing } from '../productsListing/actions'
import { actionTypeWrapper } from '../../../common/utils/actionTypeWrapper'
import { isResaleProduct } from '../../utils/privateOfferOrResaleHelperFunctions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import {
  clearSubscriptions,
  setPageNumber as setPageNumberForSubscriptions,
} from '../subscription/action'

export enum ProductListingFilterType {
  SET_CLOUD_MARKETPLACE = 'SET_CLOUD_MARKETPLACE',
}

export const setCloudMarketplaceProductListing = (cloud: CloudMarketplace) => ({
  type: ProductListingFilterType.SET_CLOUD_MARKETPLACE,
  payload: cloud,
})

export const getProductFilterListData = (products: Product[]) => {
  return [
    {
      label: 'All products',
      value: 'allProducts',
      key: 'allProducts',
    },
    ...products.map(product => ({
      label: product.productName,
      value: product.productId,
      key: product.productId,
    })),
  ]
}

export const getFlyoutProductsListBasedOnFunction = (
  products: Product[],
  functionType: FlyoutFunction
) => {
  const filteredProduct = products?.filter(
    productItem =>
      !(
        productItem?.cloudMarketplace === 'AWS' &&
        productItem.productType &&
        notAllowedProductSelection.includes(productItem?.status)
      )
  )
  switch (functionType) {
    case FlyoutFunction.REVENUE:
    case FlyoutFunction.PRIVATE_OFFERS: {
      return getProductFilterListData(filteredProduct)
    }
    case FlyoutFunction.RESALE_AUTHORIZATIONS: {
      return getProductFilterListData(
        filteredProduct.filter(product => isResaleProduct(product))
      )
    }
    case FlyoutFunction.SUBSCRIPTION: {
      return getProductFilterListData(filteredProduct)
    }
    default: {
      return []
    }
  }
}

export const updateProductIdOnFilters =
  (
    productId: string,
    cloudType: CloudMarketplace,
    functionType?: FlyoutFunction,
    isRevenueInsightsEnable?: boolean
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (functionType) {
      const cloudFilter = CloudProductFilterConstructor(
        functionType,
        isRevenueInsightsEnable
      )
      await dispatch(cloudFilter().clearData() as unknown as AnyAction)
      await dispatch(cloudFilter().setPageAsOne() as unknown as AnyAction)
    }
    await dispatch(startLoading(LoadingTypes.GENERAL))
    await dispatch(
      actionTypeWrapper(cloudType, setSelectedProductOnListing(productId))
    )
    await dispatch(stopLoading(LoadingTypes.GENERAL))
  }

export type CloudProductFiltersFunctionType =
  | (() => (dispatch: AppDispatch) => Promise<void>)
  | (() => AnyAction)

interface CloudProductFilters {
  clearData: CloudProductFiltersFunctionType
  setPageAsOne: CloudProductFiltersFunctionType
}

export const CloudProductFilterConstructor = (
  functionType: FlyoutFunction,
  isRevenueInsightsEnable?: boolean
) => {
  switch (functionType) {
    case FlyoutFunction.REVENUE:
      return isRevenueInsightsEnable
        ? getCloudProductFilterRevenueActionsV2
        : getCloudProductFilterRevenueActions
    case FlyoutFunction.PRIVATE_OFFERS:
      return getCloudgetCloudProductFilterPrivateOfferActions
    case FlyoutFunction.RESALE_AUTHORIZATIONS:
      return getCloudProductFilterResaleActions
    case FlyoutFunction.SUBSCRIPTION:
      return getCloudProductFilterSubscriptions
    default:
      return getCloudProductFilterRevenueActions
  }
}

const getCloudProductFilterResaleActions: () => CloudProductFilters = () => ({
  clearData: clearResaleAuthorizations,
  setPageAsOne: () => setResaleAuthorizationsPageNumber(1),
})

const getCloudProductFilterRevenueActions: () => CloudProductFilters = () => ({
  clearData: clearAllRevenueData,
  setPageAsOne: setPageOneForRevenue,
})

const getCloudProductFilterRevenueActionsV2: () => CloudProductFilters =
  () => ({
    clearData: clearRevenueReports,
    setPageAsOne: setPageOneForRevenueV2,
  })

const getCloudProductFilterSubscriptions: () => CloudProductFilters = () => ({
  clearData: clearSubscriptions,
  setPageAsOne: () => setPageNumberForSubscriptions(1),
})

const getCloudgetCloudProductFilterPrivateOfferActions: () => CloudProductFilters =
  () => ({
    clearData: clearPrivateOffers,
    setPageAsOne: () => setPageNumberForPrivateOffers(1),
  })
