import { makeStyles, Theme } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    borderBottom: `1px solid ${theme.palette.dividerV2.main}`,
    width: '100%',
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(32 / 8),
  },
  chip: {
    display: 'flex',
    height: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(-4),
    borderRadius: theme.spacing(4),
    backgroundColor: 'white',
    padding: `${theme.spacing(1)}px ${theme.spacing(0)}px !important`,
    gap: theme.spacing(1),
    border: 'none',
    '& .MuiChip-label': {
      textAlign: 'center',
      fontFamily: theme.typography.fontFamily,
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '0.1px',
      color: theme.palette.percentage.main,
      padding: theme.spacing(0.75, 0.75),
    },
  },
  selectedChip: {
    border: `2px solid ${theme.palette.percentage.main}`,
  },
}))
