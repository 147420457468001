import {
  LeadStage,
  ReferralStatus,
  SyncRecord,
} from '../../../components/CoSell/types'
import { SyncObjectType } from '../../salesPipeline/syncObject/reducer'
import { ReferralMetadata, ReferralReferredBy } from '../reducer'

export interface ReferralLeadAPI {
  Id: string
  Labra__Website__c: string
  Labra__Use_Case_Workload__c: string
  Labra__Title__c: string
  Labra__Street__c: string
  Labra__State__c: string
  Labra__Postal_Code__c: string
  Labra__Phone__c: string
  Labra__Level_of_AWS_Usage__c: string
  Labra__Industry__c: string
  Labra__First_Name__c: string
  Labra__Last_Name__c: string
  Labra__Full_Name__c: string
  Labra__Email__c: string
  Labra__Description__c: string
  Labra__Country__c: string
  Labra__Company_Size__c: string
  Labra__Company_Name__c: string
  Labra__City__c: string
  Labra__Campaign_Member_Status__c: string
  Labra__AWS_Lead_Status_Reason__c: string
  Labra__AWS_Lead_Status__c: string
  Labra__AWS_Lead_Source__c: string
  Labra__AWS_Lead_Age__c: string
  Labra__APN_CRM_Unique_Identifier__c: string
  Labra__ACE_Last_Modified_Date__c: string
  Labra__ACE_Created_Date__c: string
  Labra__Acceptance_Status__c: string
  Labra__Last_Updated_By__c: string
  Labra__Referred_By__c: string
  Labra__Sync_to_AWS__c: boolean
  Labra__AWS_Campaign_Name__c: string
  Labra__acceptBy__c: string
  Labra__AWS_Partner_Central_User_Name__c: string
  Labra__AWS_Partner_Central_User_Email__c: string
  Labra__Lead_Owner_Name__c: string
  Labra__Lead_Owner_Email__c: string
}

export interface ReferralLead {
  id: string
  website: string
  useCaseWorkload: string
  title: string
  address: string
  state: string
  postalCode: string
  phone: string
  levelOfAwsUsage: string
  industry: string
  firstName: string
  lastName: string
  fullName: string
  email: string
  projectDescription: string
  country: string
  segmentCompanySize: string
  companyName: string
  city: string
  campaignMemberStatus: string
  awsPartnerCentralUserName: string
  awsPartnerCentralUserEmail: string
  leadStatusReason: string
  awsLeadStatus: LeadStage
  awsLeadSource: string
  awsLeadAge: string
  partnerCRMLeadId: string
  aceLastModifiedDate: string
  aceCreatedDate: string
  acceptanceStatus: ReferralStatus
  lastUpdatedBy: string
  referredBy: ReferralReferredBy
  syncToAws: boolean
  campaignName: string
  labraAcceptByDate: string
  leadOwnerName: string
  leadOwnerEmail: string
}

export enum ReferralLeadFormSectionName {
  CUSTOMER_DETAILS = 'customerDetails',
  LEAD_DETAILS = 'leadDetails',
}

export interface CustomerDetails {
  companyName: string
  website: string
  industry: string
  city?: string
  state?: string
  country: string
  postalCode?: string
  firstName?: string
  lastName?: string
  fullName?: string
  title?: string
  email?: string
  phone?: string
  address?: string
  campaignName?: string
}

export interface LeadDetails {
  partnerCRMLeadId: string
  segmentCompanySize: string
  useCaseWorkload: string
  projectDescription: string
  industry: string
  leadStatusReason?: string
  leadOwnerName: string
  leadOwnerEmail: string
  referredBy: ReferralReferredBy
  acceptanceStatus?: ReferralStatus
  awsLeadStatus?: LeadStage
}

export type PartialLeadObject = {
  referralMetadata: Partial<ReferralMetadata>
  referralObject: Partial<ReferralLeadAPI>
  referralObjectTransformed?: Partial<ReferralLead>
  referralSyncs?: SyncRecord[]
}

export type ReferralLeadSections = {
  [ReferralLeadFormSectionName.CUSTOMER_DETAILS]: CustomerDetails
  [ReferralLeadFormSectionName.LEAD_DETAILS]: LeadDetails
}

export const mapKeysLeadApiToLead: Record<
  keyof ReferralLeadAPI,
  keyof ReferralLead
> = {
  Id: 'id',
  Labra__Website__c: 'website',
  Labra__Use_Case_Workload__c: 'useCaseWorkload',
  Labra__Title__c: 'title',
  Labra__Street__c: 'address',
  Labra__State__c: 'state',
  Labra__Postal_Code__c: 'postalCode',
  Labra__Phone__c: 'phone',
  Labra__Level_of_AWS_Usage__c: 'levelOfAwsUsage',
  Labra__Industry__c: 'industry',
  Labra__First_Name__c: 'firstName',
  Labra__Last_Name__c: 'lastName',
  Labra__Email__c: 'email',
  Labra__Description__c: 'projectDescription',
  Labra__Country__c: 'country',
  Labra__Company_Size__c: 'segmentCompanySize',
  Labra__Company_Name__c: 'companyName',
  Labra__City__c: 'city',
  Labra__Campaign_Member_Status__c: 'campaignMemberStatus',
  Labra__AWS_Partner_Central_User_Name__c: 'awsPartnerCentralUserName',
  Labra__AWS_Partner_Central_User_Email__c: 'awsPartnerCentralUserEmail',
  Labra__AWS_Lead_Status_Reason__c: 'leadStatusReason',
  Labra__AWS_Lead_Status__c: 'awsLeadStatus',
  Labra__AWS_Lead_Source__c: 'awsLeadSource',
  Labra__AWS_Lead_Age__c: 'awsLeadAge',
  Labra__APN_CRM_Unique_Identifier__c: 'partnerCRMLeadId',
  Labra__ACE_Last_Modified_Date__c: 'aceLastModifiedDate',
  Labra__ACE_Created_Date__c: 'aceCreatedDate',
  Labra__Acceptance_Status__c: 'acceptanceStatus',
  Labra__Last_Updated_By__c: 'lastUpdatedBy',
  Labra__Referred_By__c: 'referredBy',
  Labra__Sync_to_AWS__c: 'syncToAws',
  Labra__AWS_Campaign_Name__c: 'campaignName',
  Labra__acceptBy__c: 'labraAcceptByDate',
  Labra__Lead_Owner_Name__c: 'leadOwnerName',
  Labra__Lead_Owner_Email__c: 'leadOwnerEmail',
  Labra__Full_Name__c: 'fullName',
}
